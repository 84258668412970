import React from 'react';
import './SignedIn.css';
import { Button } from '@material-ui/core';
const SignedIn = ({username,handleSignout}) => {
    return ( 
        <div className="signedin">
            <div className="account__signup">
                <div className="account__signupFields">
                    <div className="signedin__info">
                        Hello {username} !!
                    </div>
                    <div className="signedin__logout" >
                    <Button variant="contained" 
                    style={{
                        borderRadius: 32,
                        backgroundColor: "#237afc",
                        padding: "16px 32px",
                        fontSize: "18px"
                    }} 
                    onClick={()=>{handleSignout()}} color="primary" size="large" 
                    >Logout</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default SignedIn;