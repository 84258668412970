import React, { useState } from 'react';
import { Button } from '@material-ui/core';

const Signup = ({handleSignup}) => {
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [error,setError] = useState(false);
    const signup = (event)=>{
        handleSignup(username,password).then((result)=>{
            console.log("successfully signed in")
        }).catch((error)=>{
            console.log(error);
            if(error.status == 'failed'){
                setError(true);
            }
            setTimeout(()=>{
                setError(false);
            },2000)
        })
    }
    const inputUsernameClass = error ? 'account__signupFields__username account__signup__input__error':'account__signupFields__username';
    const inputPasswordClass = error ? 'account__signupFields__password account__signup__input__error' : 'account__signupFields__password';

    return ( 
        <div className="account__signup">
            <div className="account__signupFields">
                <input 
                    className={inputUsernameClass} 
                    placeholder="username" 
                    onChange={(event)=>setUsername(event.target.value)}
                />
                <input 
                    className={inputPasswordClass} 
                    placeholder="password" 
                    type="password" 
                    onChange={(event)=>setPassword(event.target.value)} 
                />
                <Button variant="contained" 
                    style={{
                        borderRadius: 32,
                        backgroundColor: "#237afc",
                        padding: "16px 32px",
                        fontSize: "18px"
                    }} 
                    onClick={signup} color="primary" size="large" 
                    >Signup</Button>
            </div>
        </div>
     );
}
 
export default Signup;