import React, { useEffect, useState } from 'react';
import './Account.css';
import TaskModel from './TaskModel';
import Signup from './Signup';
import SignedIn from './SignedIn';
import {reactLocalStorage} from 'reactjs-localstorage';

const Account = ({username,closeAccount,handleSignup,handleSignout}) => {
    const [isUserLogin,setIsUserLogin] = useState(username ? true:false);
    useEffect(()=>{
        console.log(username);
        if(username){
            setIsUserLogin(true);
        }else{
            setIsUserLogin(false);
        }
    },[username]);

    
    return ( 
        <div className="account">
            {!isUserLogin && <Signup username={username} handleSignup={handleSignup} />} 
            {isUserLogin && <SignedIn username={username} handleSignout={handleSignout}/>}
            <div onClick={()=>{closeAccount()}} className="account__overlay"></div>
        </div>
     );
}
 
export default Account;