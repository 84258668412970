import React, { useState } from 'react';
import './Task.css';
import CloseIcon from '@material-ui/icons/Close';
import {useSwipeable,config} from 'react-swipeable';

const Task = ({description,taskId,handleRemove}) => {
    const [isTaskRemove,setIsTaskRemove] = useState(false);
    let taskClass = 'task';
    taskClass = isTaskRemove ? taskClass+' fadeOut':taskClass+' fadeIn';
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if(eventData.dir === 'Right'){
                setIsTaskRemove(true);                
                handleRemove(taskId);                
            }else if(eventData.dir === 'Left'){

            }
            console.log("User Swiped!", eventData)
        },
        ...config,
    });
    return (  
    <div {...handlers} className={taskClass}>
        <p>{description}</p>
        <CloseIcon className="task__close" key={taskId} onClick={()=>{handleRemove(taskId)}}/>
    </div>);
}
 
export default Task;