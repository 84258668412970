import axios from 'axios';

const axiosInstance = axios.create({
    baseURL:"https://mathekamlesh-to-do-backend.herokuapp.com/"
  })

const getTaskList = (username,token)=>{
    let config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
    }
    return new Promise((resolve,reject)=>{
        axiosInstance.get(`task`,config).then((res)=>{
            console.log(res);
            resolve(res);
        }).catch((err)=>{
            console.log(err);
            reject(err)
        });
    });    
}

const deleteTask = (taskId,token)=>{
    let config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
    }
    return new Promise((resolve,reject)=>{
        axiosInstance.delete(`task/${taskId}`,config).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });
    });    
}

const addTask = (task,token)=>{
    let config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
    }
    return new Promise((resolve,reject)=>{
        axiosInstance.post('task',task,config).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });
    });    
}

const signup = (user)=>{
    return new Promise((resolve,reject)=>{
        axiosInstance.post('auth/signup',user).then((res)=>{
            resolve(res.data);
        }).catch((err)=>{
            reject(err)
        });
    });    
}


export default {getTaskList,addTask,deleteTask,signup};