import './App.css';
import Task from './Task';
import Control from './Control';
import AddTask from './AddTask';
import Nav from './Nav';
import { useEffect, useState } from 'react';
import TaskModel from './TaskModel.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Account from './Account';
import {reactLocalStorage} from 'reactjs-localstorage';

function App() {
  const [taskList,setTaskList] = useState([]);
  const [showLoader,setShowLoader] = useState(true);
  const [showAddNewTask,setShowAddNewTask] = useState(false);  
  const [showAccount,setShowAccount] = useState(false);
  const [username,setUsername] = useState();
  const [accessToken,setAccessToken] = useState();

  useEffect(()=>{
    if( reactLocalStorage.get('username') && reactLocalStorage.get('access_token')){
        setShowLoader(true);
        setUsername(reactLocalStorage.get('username'));
        setAccessToken(reactLocalStorage.get('access_token'));
    }else{
      setTaskList([]);
      handleAccountClick();
      setShowLoader(false);
    } 
    if(username && accessToken){
      TaskModel.getTaskList(username,accessToken).then((res)=>{
        setShowLoader(false)
        setTaskList(res.data);
      }).catch((err)=>{
        handleSignout();
      });
    }  
  },[username,accessToken]);

  
  const addNewTask = (task) => {
    setShowLoader(true);
    task.userId = username;
    TaskModel.addTask(task,accessToken).then((res)=>{
      setTaskList([...taskList,...res.data.data]);
      setShowLoader(false);
    }).catch((err)=>{
      console.log(err)
    });
    setShowAddNewTask(false);
  }

  const showAddTask = ()=>{
    setShowAddNewTask(!showAddNewTask);
  }

  const handleRemove = (taskId)=>{
    setShowLoader(true);
    TaskModel.deleteTask(taskId,accessToken).then((res)=>{
      setTaskList(taskList.filter((task,index)=>{
        return taskId !== task._id
      }));
      setShowLoader(false);
    }).catch((err)=>{
      console.log(err)
    });
  }

  const handleAccountClick = () => {
    setShowAccount(true);
  }

  const closeAccount = () => {
    setShowAccount(false);
  }
  const handleCloseAddTask = () => {
    setShowAddNewTask(false);
  }
  const handleSignup = (username,password) => {
    return new Promise((resolve,reject)=>{
      TaskModel.signup({userId:username,password:password}).then((result)=>{
        setUsername(result.userId);
        reactLocalStorage.set('username',result.userId);
        reactLocalStorage.set('access_token',result.accessToken);
        closeAccount();
        resolve({status:"success"});
      }).catch((error)=>{
        reject({status:"failed"})
      });
    });    
  }

  const handleSignout = ()=>{
    reactLocalStorage.remove('username');
    reactLocalStorage.remove('access_token');
    setUsername(null);
    setAccessToken(null);
    console.log('Logout....')
  }
  return (
    <div className="app">
      <Nav username={username} handleAccountClick={handleAccountClick}  />
      {showAccount && <Account username={username} closeAccount={closeAccount} handleSignup={handleSignup} handleSignout={handleSignout} />}
      <div className="app__tasks">        
        {taskList.map((task) => <Task description={task.description} key={task._id} taskId={task._id} handleRemove={handleRemove} />)}
      </div>
      <Control handleShowAddTask={showAddTask}/>
      {showLoader && <div className="app__loader"><CircularProgress /></div>}
      {showAddNewTask && <AddTask handleNewAddTask={addNewTask} handleCloseAddTask={handleCloseAddTask}/>}
      
    </div>
  );
}

export default App;
